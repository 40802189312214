<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="DISTIRBUTERS"
          :breadcrumb="[
            {
              label: 'Dashboard',
            },
            { label: 'Distributers  ' },
          ]"
        >
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div class="card custom-card">
              <div class="card-body">


                <div class="row">
                  <div class="col text font">

                    <h4><strong style="color: #00364f; font-weight: bolder">Distributers</strong></h4>
                  </div>
                  <div class="col text-end cus-p-1">
                    <button
                      @click="takeFormNull()"
                      class="btn btn-sm text-white"
                      style="background-color: #f21300"
                      data-bs-toggle="modal"
                      data-bs-target="#Retailers"
                    >
                      <b>+ Add New</b>
                    </button> &nbsp;
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#new-task-modal"
                      title="Filter"
                      class="btn btn-sm text-white"
                      style="background-color: #00364f"
                    >
                      <strong> <font-awesome-icon icon="filter" /></strong>
                    </button>

                  </div>
                    <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" >

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row ">
                  <div class="d-md-none d-xl-none d-block col-3 text font cus-p-1">

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
                    <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>
               
                </div>
                <!-- start list area  -->


                
                <!-- start list area  -->
                <div class="row">
                
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                   
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th>&nbsp;&nbsp;+&nbsp;&nbsp;</th>
                          <th>Code</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th class="text-truncate">No. of Retailers</th>
                          <th class="text-center">Kyc</th>
                          <th class="text-center">Status</th>
                          <th class="text-center">Action</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(distributer, index) in distributers"
                            :key="index"
                          >
                            <td>
                              <img
                                style="width: 30px; height: 30px"
                                :src="profilePhotoFinder(distributer)"
                                alt=""
                              />
                            </td>
                           
                            <td>{{ distributer.code }}</td>
                            <td class="text-truncate">{{ distributer.name }}</td>
                            <td>{{ distributer.email }}</td>
                            <td>{{ distributer.phone }}</td>
                            <td>
                              <span v-if="distributer">
                                {{ distributer.retailers_count }}
                              </span>
                            </td>
                            <td style="padding: 0px 15px 0px 15px" class="text-truncate">
                              <span v-if="distributer.distributer_kyc">
                                <p
                                  v-if="
                                    distributer.distributer_kyc.kycStatus ==
                                    'approve'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: green;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>
                                    {{
                                      distributer.distributer_kyc.kycStatus
                                    }}</b
                                  >
                                </p>

                                <p
                                  v-if="
                                    distributer.distributer_kyc.kycStatus ==
                                    'cancel'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: #f21000;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>
                                    {{
                                      distributer.distributer_kyc.kycStatus
                                    }}</b
                                  >
                                </p>

                                <p
                                  v-if="
                                    distributer.distributer_kyc.kycStatus ==
                                    'reject'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: black;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>
                                    {{
                                      distributer.distributer_kyc.kycStatus
                                    }}</b
                                  >
                                </p>

                                <p
                                  v-if="
                                    distributer.distributer_kyc.kycStatus ==
                                    'false'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: rgb(235, 133, 16);
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                <b>Pending</b
                                  >
                                </p>
                              </span>
                              <span v-else> 
                                <p
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: rgb(235, 133, 16);
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>Pending</b
                                  >
                                </p>
                              </span>
                            </td>
                            <td class="text-center">
                              <p
                                v-if="distributer.loginStatus == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  box-shadow: 2px 2px 5px gray;
                                  width: 70px;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                Active
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  box-shadow: 2px 2px 5px gray;
                                  width: 70px;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                InActive
                              </p>
                            </td>
                            <td class="text-end">
                              <div
                                class="btn-group btn-group-sm"
                                role="group"
                                aria-label="..."
                              >
                                <router-link
                                  class="btn btn-sm text-white"
                                  :to="`/master-distributer/distributers-view/${distributer.id}`"
                                  style="
                                    background-color: #00364f;
                                    padding: 5px 4.5px 5px 4.5px;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link>

                                <button
                                  data-bs-toggle="modal"
                                  data-bs-target="#Retailers"
                                  @click="valueAssignRetailer(distributer)"
                                  type="button"
                                  class="btn btn-sm btn-success"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>


<!-- 
                                <button
                                  type="button"
                                  class="btn btn-sm text-white"
                                  @click="distributerkycValueAsign(distributer)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#kycModal"
                                  style="
                                    background-color: blue;
                                    padding: 5px 4.5px 5px 4.5px;
                                  "
                                >
                                  <i class="fa fa-user" aria-hidden="true"></i>
                                </button> -->



                                <router-link
                                  title="Kyc"
                                  to="#"
                                  @click="distributerkycValueAsign(distributer)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#kycModal"
                                  class="btn btns btn-sm text-dark"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: yellow;
                                  "
                                >
                                  <font-awesome-icon icon="file" />
                                </router-link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
      
              <div class="card-footer">
                    <div class="row">

                      <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                        <p class="mb-0">
                          Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                        </p>
                      </div>
                      <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                        <ul class="pagination  justify-content-center">
                          <li class="page-item" @click="loadRetailers(null, 'first')">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                            </a>
                          </li>
                          <li class="page-item" @click="loadRetailers(null, 'prev')">
                            <a class="page-link" href="#">Previous</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page > 3"
                            @click="loadRetailers(pagination.current_page - 3)">
                            <a class="page-link" href="#">{{
                              pagination.current_page - 3
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page > 2"
                            @click="loadRetailers(pagination.current_page - 2)">
                            <a class="page-link" href="#">{{
                              pagination.current_page - 2
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page > 1"
                            @click="loadRetailers(pagination.current_page - 1)">
                            <a class="page-link" href="#">{{
                              pagination.current_page - 1
                            }}</a>
                          </li>
                          <li class="active page-item" @click="loadRetailers(pagination.current_page)">
                            <a class="page-link" href="#">{{ pagination.current_page }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                            @click="loadRetailers(pagination.current_page + 1)">
                            <a class="page-link" href="#">{{
                              pagination.current_page + 1
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                            @click="loadRetailers(pagination.current_page + 2)">
                            <a class="page-link" href="#">{{
                              pagination.current_page + 2
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                            @click="loadRetailers(pagination.current_page + 3)">
                            <a class="page-link" href="#">{{
                              pagination.current_page + 3
                            }}</a>
                          </li>
                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadRetailers( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                          <li class="page-item">
                            <a class="page-link" href="#">...</a>
                          </li>

                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadRetailers( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                            @click="loadRetailers(pagination.last_page - 3)">
                            <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                            @click="loadRetailers(pagination.last_page - 2)">
                            <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                            @click="loadRetailers(pagination.last_page - 1)">
                            <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                          </li>

                          <li class="page-item" @click="loadRetailers(null, 'next')">
                            <a class="page-link" href="#">Next</a>
                          </li>
                          <li class="page-item" @click="loadRetailers(null, 'last')">
                            <a class="page-link" href="#">&raquo;</a>
                          </li>
                        </ul>
                      </div>

                      <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="kycModal"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Retailer Kyc</b></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger p-1 mt-1" role="alert" v-if="msgImgError">
                {{ msgImgError }}
              </div>
            </div>
          </div>

          <form
            @submit.prevent="
              distributerKycVal == 'false' ? distributerkycCreate() : distributerkycUpdate()
            "
          >
            <div class="container-fluid table-scroll">
              <table class="table table-hover table-sm">
                <tr class="text table-wrapper-scroll-y rounded-circle tr-head">
                  <th class="text-truncate">+</th>
                  <th class="text-truncate">Document</th>
                  <th class="text-truncate">Action</th>
                </tr>

                <tbody class="text">
                  <tr>
                    <td>
                      <img
                        style="width: 30px; height: 30px"
                        :src="adharFrontImg"
                        alt=""
                      />
                    </td>
                    <td class="text-truncate">
                      <strong class="text">Aadhaar Front</strong>
                      <input
                        type="number"
                        maxlength="12"
                        required
                        v-model="formKyc.aadharNo"
                        class="form-control"
                        @change="lenthheker($event, 'professionalKycAddar')"
                        placeholder="Adhar Number"
                      />
                      <span style="color: #f21000" id="professionalKycAddar"></span>

                      <small style="color: red" v-if="formKyc.errors"
                        ><span v-if="formKyc.errors.error"
                          ><span v-if="formKyc.errors.error.aadharNo">{{
                            formKyc.errors.error.aadharNo[0]
                          }}</span></span
                        ></small
                      >

                    </td>
                    <td class="text-truncate">
                      <label for="AdharFront">
                        <img
                          style="width: 25px; height: 25px"
                          src="/assets/image/upload.png"
                        />
                      </label>
                      <input
                        @change="uploadAdharFront"
                        class="input-file"
                        style="position: absolute; top: -150000px"
                        id="AdharFront"
                        type="file"
                        accept="image/*"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img style="width: 30px; height: 30px" :src="adharBackImg" alt="" />
                    </td>
                    <td class="text-truncate">
                      <strong class="text">Aadhaar Back</strong>
                    </td>
                    <td class="text-truncate">
                      <label for="AdharBack">
                        <img
                          style="width: 25px; height: 25px"
                          src="/assets/image/upload.png"
                        />
                      </label>
                      <input
                        @change="uploadAdharBack"
                        class="input-file"
                        style="position: absolute; top: -150000px"
                        id="AdharBack"
                        type="file"
                        accept="image/*"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img style="width: 30px; height: 30px" :src="panImg" alt="" />
                    </td>
                    <td class="text-truncate">
                      <strong class="text">Pan</strong>
                      <!-- <input
                        maxlength="10"
                        type="text"
                        v-model="formKyc.panNo"
                        class="form-control"
                        placeholder="Pan Number"
                        @change="businessPanVeryfied()"
                      /> -->

                      <input
                        v-if="panVarifyingStatus == 'true'"
                        disabled
                        type="tel"
                        maxlength="10"
                        v-model="formKyc.panNo"
                        class="form-control"
                        placeholder="Pan Number"
                        style="height: 30px; text-transform: uppercase"
                        required
                        @change="businessPanVeryfied()"
                        @keydown="nameKeydown($event)"
                      />
                      <input
                        v-else
                        type="tel"
                        maxlength="10"
                        v-model="formKyc.panNo"
                        class="form-control"
                        placeholder="Pan Number"
                        style="height: 30px; text-transform: uppercase"
                        required
                        @change="businessPanVeryfied()"
                        @keydown="nameKeydown($event)"
                      />
                      <span style="color: #f21000" id="adminPanVeryfied"></span>
                      <small style="color: red" v-if="formKyc.errors"
                        ><span v-if="formKyc.errors.error"
                          ><span v-if="formKyc.errors.error.panNo">{{
                            formKyc.errors.error.panNo[0]
                          }}</span></span
                        ></small
                      >
                    </td>
                    <td class="text-truncate">
                      <label for="panphoto">
                        <img
                          style="width: 25px; height: 25px"
                          src="/assets/image/upload.png"
                        />
                      </label>
                      <input
                        @change="uploadPanPhoto"
                        class="input-file"
                        style="position: absolute; top: -150000px"
                        id="panphoto"
                        type="file"
                        accept="image/*"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="Retailers"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel">
            <b>Retailer {{ heading }}</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
     
     <div v-if="msgImgError">
      <div class="alert alert-danger p-1" role="alert">
 {{msgImgError}}
</div>
     </div>

          <form
            @submit.prevent="edit == 'false' ? createNewRetailer() : updateRetailer()"
          >
            <div class="row">
              <div class="col-lg-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-1">
                <label for="image-upload">
                  <img
                    style="width: 70px; height: 70px; border-radius: 5px"
                    :src="photo"
                    alt=""
                  />
                </label>
                <input
                  @change="uploadImg"
                  class="input-file"
                  style="position: absolute; top: -150000px"
                  id="image-upload"
                  type="file"
                  accept="image/*"
                />
              </div>

              <div
                class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Name <small style="color: #f21000">*</small></label>
                  <input
                    required
                    type="text"
                    v-model="form.name"
                    class="form-control"
                    placeholder="Name"
                    style="height: 30px"
                  />
                </div>
              </div>

          <div
                class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>DOB <small style="color: #f21000">*</small></label>
                  <input
                    required
                    v-model="form.dob"
                    type="date"
                    class="form-control"
                    placeholder="Name"
                    style="height: 30px"
                  />
                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.dob">{{
                        form.errors.error.dob[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>
              <div
                class="col-xl-4 col-lg-4 col-md-4 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Gender <small style="color: #f21000">*</small></label>

                  <select
                    required
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    v-model="form.gender"
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option selected disabled>Select...</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.gender">{{
                        form.errors.error.gender[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>

              <div
                class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Address <small style="color: #f21000">*</small></label>
                  <input
                    required
                    v-model="form.address"
                    type="text"
                    class="form-control"
                    placeholder="Address"
                    style="height: 30px"
                  />
                </div>
              </div>
              <div
                class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>State <small style="color: #f21000">*</small></label>

                  <select
                    required
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    v-model="form.state_id"
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option
                      v-for="(state, index) in states"
                      :key="index"
                      :value="state.id"
                    >
                      {{ state.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>City</label>
                  <input
                    type="text"
                    v-model="form.city"
                    class="form-control"
                    placeholder="City"
                    style="height: 30px"
                  />
                </div>
              </div>
              <div
                class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Pin <small style="color: #f21000">*</small></label>

                  <input
                    required
                    v-model="form.pin"
                    type="number"
                    class="form-control"
                    placeholder="Pin Code "
                    style="height: 30px"
                    @change="lenthheker($event, 'pinCode')"
                  />
                  <span style="color: #f21000" id="pinCode"></span>
                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.pin">{{
                        form.errors.error.pin[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>

              <div
                class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Phone <small style="color: #f21000">*</small></label>
                  <input
                    required
                    type="number"
                    v-model="form.phone"
                    class="form-control"
                    placeholder="Mobile Number"
                    style="height: 30px"
                    @change="lenthheker($event, 'phonePro')"
                  />
                  
                  <span style="color: #f21000" id="phonePro"></span>
                </div>
                <small style="color: red" v-if="form.errors"
                  ><span v-if="form.errors.error"
                    ><span v-if="form.errors.error.phone">{{
                      form.errors.error.phone[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div
                class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label> Alternative Phone</label>
                  <input
                    type="number"
                    v-model="form.alternativePhone"
                    class="form-control"
                    placeholder="Alternative Phone Number"
                    style="height: 30px"
                    @change="lenthheker($event, 'altPhonePro')"
                  />
                  <span style="color: #f21000" id="altPhonePro"></span>
                </div>
              </div>

              <div
                class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Email <small style="color: #f21000">*</small></label>
                  <input
                    required
                    type="email"
                    v-model="form.email"
                    class="form-control"
                    placeholder="Email"
                    style="height: 30px"
                  />
                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.email">{{
                        form.errors.error.email[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>

              <div
                class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Password</label>
                  <input
                    v-model="form.password"
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    style="height: 30px"
                    @change="lenthheker($event, 'proPassword')"
                  />
                  <span style="color: #f21000" id="proPassword"></span>
                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.password">{{
                        form.errors.error.password[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>
              <!-- <div
                class="col-xl-3 col-md-3 col-lg-3 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group">
                  <label for="specialization">Specialization </label>
                  <input
                    v-model="form.specialization"
                    type="text"
                    class="form-control"
                    placeholder="Specialization"
                    id="specialization"
                    style="height: 30px"
                  />
                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.specialization">{{
                        form.errors.error.specialization[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div> -->

              <div
                class="col-xl-3 col-md-3 col-lg-3 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group">
                  <label for="status">Status </label>
                  <select
                    v-model="form.loginStatus"
                    class="form-select"
                    aria-label="Default select example"
                    style="height: 30px; padding: 5px 5px 5px 5px"
                  >
                    <option selected disabled>Status...</option>
                    <option value="false">InActive</option>
                    <option value="true">Active</option>
                  </select>
                </div>
              </div>

              <h4 class="mt-1" style="color: #00364f">Bussiness Details</h4>
              <div
                class="col-xl-3 col-md-3 col-lg-3 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group">
                  <label for="bussinessPan">Bussiness Pan </label>
                  <!-- <input
                    v-model="form.bussinessPan"
                    type="text"
                    class="form-control"
                    placeholder="Bussiness Pan"
                    id="bussinessPan"
                    style="height: 30px"
                  /> -->

                  <span v-if="panVerifyForFirm == 'true'">
                    <input
                      disabled
                      style="height: 30px"
                      v-model="form.bussinessPan"
                      type="tel"
                              maxlength="10"
                      class="form-control"
                      placeholder="Bussiness Pan"
                      id="bussinessPan"
                      @keydown.enter.prevent
                      @change="panVeryfied()"
                      @keydown="nameKeydown($event)"
                    />
                  </span>
                  <span v-else>
                    <input
                    @keydown.enter.prevent
                    style="height: 30px"
                    maxlength="10"
                      v-model="form.bussinessPan"
                      type="text"
                      class="form-control"
                      placeholder="Bussiness Pan"
                      id="bussinessPan"
                      
                      @change="panVeryfied()"
                      @keydown="nameKeydown($event)"
                    />
                  </span>

                  <span style="color: #f21000" id="firmPanVeryfied"></span>



                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.bussinessPan">{{
                        form.errors.error.bussinessPan[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>

              <div
                class="col-xl-3 col-md-3 col-lg-3 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group">
                  <label for="bussinessName">Firm Name </label>
                  <input
                    v-model="form.bussinessName"
                    type="text"
                    class="form-control"
                    placeholder="Firm Name"
                    id="bussinessName"
                    style="height: 30px"
                  />
                  <span style="color: #f21000" id="bussinessNameMaster"></span>
                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.bussinessName">{{
                        form.errors.error.bussinessName[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>

              <div
                class="col-xl-3 col-md-3 col-lg-3 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group">
                  <label for="bussinessAddress"> Firm Address </label>
                  <input
                    type="text"
                    v-model="form.bussinessAddress"
                    class="form-control"
                    placeholder="Firm Address "
                    id="bussinessAddress"
                    style="height: 30px"
                  />
                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.bussinessAddress">{{
                        form.errors.error.bussinessAddress[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>

              <div
                class="col-xl-3 col-md-3 col-lg-3 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group">
                  <label for="state">Firm State </label>
                  <select
                    v-model="form.bussiness_state_id"
                    class="form-select"
                    aria-label="Default select example"
                    style="height: 30px; padding: 5px 5px 5px 5px"
                  >
                    <option selected disabled>slect..</option>
                    <option
                      :value="state.id"
                      v-for="(state, index) in states"
                      :key="index"
                    >
                      {{ state.name }}
                    </option>
                  </select>
                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.bussiness_state_id">{{
                        form.errors.error.bussiness_state_id[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>

              <div
                class="col-xl-3 col-md-3 col-lg-3 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group">
                  <label for="bussinessCity">Firm City </label>
                  <input
            
                    type="text"
                    v-model="form.bussinessCity"
                    class="form-control"
                    placeholder="Firm City"
                    id="bussinessCity"
                    style="height: 30px"
                 
                  />
                 
                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.bussinessCity">{{
                        form.errors.error.bussinessCity[0]
                      }}</span></span
                    ></small
                  >
                </div>
                
              </div>
              <div
                class="col-xl-3 col-md-3 col-lg-3 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group">
                  <label for="bussinessPin">Bussiness Pin Code</label>
                  <input
                  
                    type="number"
                    v-model="form.bussinessPin"
                    class="form-control"
                    placeholder="Bussiness Pin Code"
                    id="bussinessPin"
                    style="height: 30px"
                    @change="lenthheker($event, 'businessPinCode')"
                  />
                  <span style="color: #f21000" id="businessPinCode"></span>
                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.bussinessPin">{{
                        form.errors.error.bussinessPin[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>

              <div
                class="col-xl-3 col-md-3 col-lg-3 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group">
                  <!-- <small style="color: #f21000">*</small> -->
                  <label for="gstin">GSTIN </label>
                  <input
                  
                    type="tel"
                    maxlength="15"
                    v-model="form.gstin"
                    class="form-control"
                    placeholder="GSTIN"
                    id="gstin"
                    style="height: 30px"
                    @change="lenthheker($event, 'gstinVal')"
                  />
                  <span style="color: #f21000" id="gstinVal"></span>
                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.gstin">{{
                        form.errors.error.gstin[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>

              <div
                class="col-xl-3 col-md-3 col-lg-3 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group">
                  <label for="doi">DOI </label>
                  <input
                    type="date"
                    v-model="form.doi"
                    class="form-control"
                    placeholder="Pin Code"
                    id="doi"
                    style="height: 30px"
                  />
                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.doi">{{
                        form.errors.error.doi[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>

              <div
                class="col-xl-3 col-md-3 col-lg-3 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group">
                  <label for="cin">CIN</label>
                  <input
                    type="tel"
                    v-model="form.cin"
                    class="form-control"
                    placeholder="CIN"
                    id="cin"
                    style="height: 30px"
                    @change="lenthheker($event, 'cinVal')"
                  />
                  <span style="color: #f21000" id="cinVal"></span>
                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.cin">{{
                        form.errors.error.cin[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>

              <div
                class="col-xl-3 col-md-3 col-lg-3 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group">
                  <label for="tds">TAN</label>
                  <input
                    type="tel"
                    v-model="form.tds"
                    class="form-control"
                    placeholder="TAN No."
                    id="tds"
                    style="height: 30px"
                    @change="lenthheker($event, 'tdsVal')"
                  />
                  <span style="color: #f21000" id="tdsVal"></span>
                  <small style="color: red" v-if="form.errors"
                    ><span v-if="form.errors.error"
                      ><span v-if="form.errors.error.tds">{{
                        form.errors.error.tds[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div> 

              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Remark</label>
                  <textarea
                    v-model="form.remark"
                    class="form-control"
                    rows="3"
                    placeholder="Remark"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                <b>Close</b>
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


  
  <!-- Right Sidebar starts -->
  <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
            <ul class="list-group list-group-flush">
  <li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="code" class="form-check-input" type="radio" name="flexRadioDefault" id="code">
  <label class="form-check-label" for="code">
    Code
  </label>
</div>
  </li>
  <li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="name" class="form-check-input" type="radio" name="flexRadioDefault" id="name">
  <label class="form-check-label" for="name">
    Name
  </label>
</div>
  </li>
  <li class="list-group-item"><div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="email" class="form-check-input" type="radio" name="flexRadioDefault" id="email">
  <label class="form-check-label" for="email">
    Email
  </label>
</div></li>
  <li class="list-group-item"><div class="form-check">
  <input @click="valueNullFilter()" v-model="filterType" value="phone" class="form-check-input" type="radio" name="flexRadioDefault" id="phone">
  <label class="form-check-label" for="phone">
    Phone
  </label>
</div></li>


<li class="list-group-item">
    <div class="form-check">
  <input @click="valueNullFilter()" v-model="filterType" value="all" class="form-check-input" type="radio" name="flexRadioDefault" id="all">
  <label class="form-check-label" for="all">
    All
  </label>
</div>
  </li>
</ul>
            <div class="pt-1 pb-1">
              <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
              <!-- <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'" class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
              <input
              :type="filterType == 'phone' ? 'number' : 'text'"
                v-if="filterType != 'all'"
                class="form-control"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />
              <!-- <input type="text" v-if="filterType == 'client_id'" @change="findRecordeByPanId" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
            </div>

            <button
              @click="loadRetailers()"
              type="button"
              class="btn btn-success btn-sm"
            >
              Success
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Banner from "../../../components/master-distributer/comman/Banner.vue";
import Spinner from "../../../components/master-distributer/comman/Spinner.vue";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "MasterDistributerRetailers",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      panVerifyForFirm: "false",
      submitStatusForFirm: "false",
      msgImgError: "",
      extensionProfile: "",
      filterValue: "",
      filterType: "",
      distributerEdit: "false",

      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      loading: false,
      kycModalHeading: "Create ",
      distributerKycs: [],
      heading: "Create",
      photo: this.$store.state.placeholderImg,
      portal: "",
      distributers: [],
      allAdmins: [],
      admin_id: null,
      edit: "false",

      form: {
        code: "",
        photo: "",
        name: "",
        email: "",
        password: "",
        phone: "",

        city: "",
        pin: "",
        gender: "",
        dob: "",
        address: "",
        alternativePhone: "",
        state_id: null,

        secretKey: "",
        remark: "",
        errors: "",
      },

      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      distributerKycVal: "false",
      formKyc: {
        panNo: null,
        panPhoto: "",
        accountType: "",
        accountNo: null,
        passbookPhoto: "",
        aadharNo: null,
        aadharFrontPhoto: "",
        aadharBackPhoto: "",
        remark: "",
        errors:{}
      },
      distributer_id: null,
      states: [],
      panVarifyingStatus: "false",

      proPassword: "false",
      pinCode: "false",
      phonePro: "false",
      altPhonePro: "false",

      tdsVal: "false",
      cinVal: "false",

      gstinVal: "false",
      businessPinCode: "false",

      gender: "false",
      

      


    };
  },
  methods: {
    panVeryfied() {
      var data = this.form.bussinessPan;
      if (data.length == 10) {
        this.submitStatusForFirm = "false";
        $("#firmPanVeryfied").html("<small></small>");
        // this.msgPanError = "";
        this.loading = true;
        this.$axios
          .post(
            "masterdistributer/verifypan",
            { panNo: this.form.bussinessPan },
            { headers: { Authorization: "Bearer " + localStorage.accessToken } }
          )
          .then((res) => {
            console.log(res.data.data);
            if (res.data.data.message) {
              this.submitStatusForFirm = "true";
              $("#firmPanVeryfied").html("<small>" + res.data.data.message + "</small>");
            }
            if (res.data.data.data.status != "VALID") {
              $("#firmPanVeryfied").html(
                "<small>" + res.data.data.data.status + " Pan </small>"
              );
            }
            if (res.data.data.data.status == "VALID") {
              this.submitStatusForFirm = "false";
              $("#firmPanVeryfied").html("<small></small>");
              this.panVerifyForFirm = "true";
              toast.success("Pan Verified Succesfull", {
                autoClose: 1000,
              });
            } else {
              this.submitStatusForFirm = "true";
            }
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.submitStatusForFirm = "true";
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
      } else {
        if(data.length == 0){
          this.submitStatusForFirm = "true";
        $("#firmPanVeryfied").html("<small></small>");
        }else{
            this.submitStatusForFirm = "true";
        $("#firmPanVeryfied").html("<small> Pan required 10 Digits</small>");
        }
      
      }

      // this.panNo = ''
    },

    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "professionalKycAddar") {
        if (data.length != 12) {
          this.professionalKycAddar = "true";

          $("#professionalKycAddar").html(
            "<small>Aadhar required  12 Digits</small>"
          );
        }
        if (data.length == 12) {
          this.professionalKycAddar = "false";
          $("#professionalKycAddar").html("<small></small>");
        }
      }



// master masterdistributer create form 

if (type == "professionalName") {
        if (data.length < 3) {
          this.professionalName = "true";
          $("#professionalName").html(
            "<small>Name required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          this.professionalName = "false";
          $("#professionalName").html("<small></small>");
        }
      }
      if (type == "bussinessNameMaster") {
        if (data.length < 3) {
          this.bussinessNameMaster = "true";
          $("#bussinessNameMaster").html(
            "<small> Name required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          this.bussinessNameMaster = "false";
          $("#bussinessNameMaster").html("<small></small>");
        }
      }

      if (type == "phonePro") {
        if (data.length != 10) {
          this.phonePro = "true";
          $("#phonePro").html("<small>Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          this.phonePro = "false";
          $("#phonePro").html("<small></small>");
        }
      }
      if (type == "altPhonePro") {
        if (data.length != 10) {
          if(data.length == 0){
            this.altPhonePro = "false";
          $("#altPhonePro").html("<small></small>");
          }else{
             this.altPhonePro = "true";
          $("#altPhonePro").html("<small>Alt Phone Number 10 Digit Required</small>");
          }
         
        }
        if (data.length == 10) {
          this.altPhonePro = "false";
          $("#altPhonePro").html("<small></small>");
        }
      }

      if (type == "profesionalAddress") {
        if (data.length < 3) {
          this.profesionalAddress = "true";
          $("#profesionalAddress").html(
            "<small>Address required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          this.profesionalAddress = "false";
          $("#profesionalAddress").html("<small></small>");
        }
      }
      if (type == "profesionalCity") {
        if (data.length < 3) {
          this.profesionalCity = "true";
          $("#profesionalCity").html("<small>City required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.profesionalCity = "false";
          $("#profesionalCity").html("<small></small>");
        }
      }

      if (type == "businessAddressMaster") {
        if (data.length < 3) {
          this.businessAddressMaster = "true";
          $("#businessAddressMaster").html("<small>Address required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.businessAddressMaster = "false";
          $("#businessAddressMaster").html("<small></small>");
        }
      }

      if (type == "bussinessCityMaster") {
        if (data.length < 3) {
          this.bussinessCityMaster = "true";
          $("#bussinessCityMaster").html("<small>City required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.bussinessCityMaster = "false";
          $("#bussinessCityMaster").html("<small></small>");
        }
      }

      if (type == "businessPinCode") {
        if (data.length != 6) {
          if (data.length == 0) {
          this.businessPinCode = "false";
          $("#businessPinCode").html("<small></small>");
        }else{
           this.businessPinCode = "true";
          $("#businessPinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
         
        }
        if (data.length == 6) {
          this.businessPinCode = "false";
          $("#businessPinCode").html("<small></small>");
        }
      }

      if (type == "pinCode") {
        if (data.length != 6) {
          this.pinCode = "true";
          $("#pinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          this.pinCode = "false";
          $("#pinCode").html("<small></small>");
        }
      }

      if (type == "proPassword") {
        if (data.length < 6) {
          if(data.length == 0){
            this.proPassword = "false";
          $("#proPassword").html("<small></small>");
          }else{
             this.proPassword = "true";
          $("#proPassword").html("<small>Password Minimum 6 Digits Required</small>");
          }
         
        }
        if (data.length >= 6) {
          this.proPassword = "false";
          $("#proPassword").html("<small></small>");
        }
      }

      if (type == "gstinVal") {
        if (data.length != 15) {
            if(data.length == 0){
              this.gstinVal = "false";
          $("#gstinVal").html("<small></small>");
            }else{
              this.gstinVal = "true";
          $("#gstinVal").html("<small>GSTIN 15 Digit Required</small>");
            }
          
        }
        if (data.length == 15) {
          this.gstinVal = "false";
          $("#gstinVal").html("<small></small>");
        }
      }



      if (type == "cinVal") {
        if (data.length != 21) {
            if(data.length == 0){
              this.cinVal = "false";
          $("#cinVal").html("<small></small>");
            }else{
              this.cinVal = "true";
          $("#cinVal").html("<small>CIN 21 Digit Required</small>");
            }
          
        }
        if (data.length == 21) {
          this.cinVal = "false";
          $("#cinVal").html("<small></small>");
        }
      }


      if (type == "tdsVal") {
        if (data.length != 10) {
            if(data.length == 0){
              this.tdsVal = "false";
          $("#tdsVal").html("<small></small>");
            }else{
              this.tdsVal = "true";
          $("#tdsVal").html("<small>TAN 10 Digit Required</small>");
            }
          
        }
        if (data.length == 10) {
          this.tdsVal = "false";
          $("#tdsVal").html("<small></small>");
        }
      }


    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadRetailers();
    },
    takeFormNull() {
      this.form = {};
      this.form.errors = {};
      this.edit = "false";
      this.photo = this.$store.state.placeholderImg;
      this.msgImgError = ""
      this.panVarifyingStatus = 'false'
      this.submitStatusForFirm = "false";
        $("#firmPanVeryfied").html("<small></small>");

      this.phonePro = "false";
          $("#phonePro").html("<small></small>");

          this.altPhonePro = "false";
          $("#altPhonePro").html("<small></small>");

          this.businessPinCode = "false";
          $("#businessPinCode").html("<small></small>");


          this.pinCode = "false";
          $("#pinCode").html("<small></small>");


          this.proPassword = "false";
          $("#proPassword").html("<small></small>");


          this.gstinVal = "false";
          $("#gstinVal").html("<small></small>");


          this.cinVal = "false";
          $("#cinVal").html("<small></small>");


          this.altPhonePro = "false";
          $("#altPhonePro").html("<small></small>");


          this.tdsVal = "false";
          $("#tdsVal").html("<small></small>");


          this.submitStatusForFirm = "false";
              $("#firmPanVeryfied").html("<small></small>");
              this.panVerifyForFirm = "false";

    },
    uploadAdharFront(e) {
      // this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
      // this.formKyc.aadharFrontPhoto = e.target.files["0"];
      // var fileName;
      // fileName = document.querySelector("#AdharFront").value;
      // this.extensionkycAdharFront = fileName.split(".").pop();

      // if (this.extensionkycAdharFront == "pdf") {
      //   this.adharFrontImg = "/assets/image/pdf.png";
      // }




      this.msgImgError = "";
      var fileName;
      fileName = document.querySelector("#AdharFront").value;
      this.extensionkycAdharFront = fileName.split(".").pop();

      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        if (
           this.extensionkycAdharFront == "png" ||
          this.extensionkycAdharFront == "jpeg" ||
          this.extensionkycAdharFront == "jpg" ||
          this.extensionkycAdharFront == "pdf" ||
          this.extensionkycAdharFront == "PNG" ||
          this.extensionkycAdharFront == "JPEG" ||
          this.extensionkycAdharFront == "JPG" ||
          this.extensionkycAdharFront == "PDF"
        ) {
          this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
          this.formKyc.aadharFrontPhoto = e.target.files["0"];

          if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
            this.adharFrontImg = "/assets/image/pdf.png";
          }
        } else {
          this.msgImgError = "Aadhar Front File type must JPG ,JPEG, PNG , PDF ";
        }
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    uploadAdharBack(e) {
      // this.adharBackImg = URL.createObjectURL(e.target.files["0"]);
      // this.formKyc.aadharBackPhoto = e.target.files["0"];
      // var fileName;
      // fileName = document.querySelector("#AdharBack").value;
      // this.extensionkycAdharBack = fileName.split(".").pop();

      // if (this.extensionkycAdharBack == "pdf") {
      //   this.adharBackImg = "/assets/image/pdf.png";
      // }



      this.msgImgError = "";
      var fileName;
      fileName = document.querySelector("#AdharBack").value;
      this.extensionkycAdharBack = fileName.split(".").pop();

      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        if (
           this.extensionkycAdharBack == "png" ||
          this.extensionkycAdharBack == "jpeg" ||
          this.extensionkycAdharBack == "jpg" ||
          this.extensionkycAdharBack == "pdf" ||
          this.extensionkycAdharBack == "PNG" ||
          this.extensionkycAdharBack == "JPEG" ||
          this.extensionkycAdharBack == "JPG" ||
          this.extensionkycAdharBack == "PDF"
        ) {
          this.adharBackImg = URL.createObjectURL(e.target.files["0"]);
          this.formKyc.aadharBackPhoto = e.target.files["0"];

          if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
            this.adharBackImg = "/assets/image/pdf.png";
          }
        } else {
          this.msgImgError = "Aadhar Back File type must JPG ,JPEG, PNG , PDF ";
        }
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    uploadPassbookPhoto(e) {
      this.passBookImg = URL.createObjectURL(e.target.files["0"]);
      this.formKyc.passbookPhoto = e.target.files["0"];
    },
    uploadPanPhoto(e) {
      // this.panImg = URL.createObjectURL(e.target.files["0"]);
      // this.formKyc.panPhoto = e.target.files["0"];
      // var fileName;
      // fileName = document.querySelector("#panphoto").value;
      // this.extensionkycPanPhoto = fileName.split(".").pop();

      // if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
      //   this.panImg = "/assets/image/pdf.png";
      // }


      
      this.msgImgError = "";
      var fileName;
      fileName = document.querySelector("#panphoto").value;
      this.extensionkycPanPhoto = fileName.split(".").pop();

      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        if (
           this.extensionkycPanPhoto == "png" ||
          this.extensionkycPanPhoto == "jpeg" ||
          this.extensionkycPanPhoto == "jpg" ||
          this.extensionkycPanPhoto == "pdf" ||
          this.extensionkycPanPhoto == "PNG" ||
          this.extensionkycPanPhoto == "JPEG" ||
          this.extensionkycPanPhoto == "JPG" ||
          this.extensionkycPanPhoto == "PDF"
        ) {
          this.panImg = URL.createObjectURL(e.target.files["0"]);
          this.formKyc.panPhoto = e.target.files["0"];

          if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
            this.panImg = "/assets/image/pdf.png";
          }
        } else {
          this.msgImgError = "Pan File type must JPG ,JPEG, PNG , PDF ";
        }
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    distributerKycLoad() {
      this.$axios
        .get("masterdistributer/distributerkyc", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.distributerKycs = res.data.data.data;
          console.log(this.distributerKycs);
        });
    },
    distributerkycValueAsign(value) {
      this.formKyc = {} 
      this.formKyc.errors = {}
      this.professionalKycAddar = "false";
          $("#professionalKycAddar").html("<small></small>");

          this.submitStatus = "false";
        $("#adminPanVeryfied").html("<small></small>");

      console.log(value.distributer_kyc);
this.msgImgError = ""
      this.distributer_id = value.id;
      if (value.distributer_kyc == null) {
        // alert('distributerkycCreate')
        this.kycModalHeading = "Create";
        this.distributerKycVal = "false";
        this.panVarifyingStatus = 'false'
        this.formKyc.panNo = "";
        this.formKyc.panPhoto = "";
        this.formKyc.accountType = "";
        this.formKyc.aadharNo = "";
        this.formKyc.remark = "";
        this.formKyc.aadharBackPhoto = "";
        this.formKyc.aadharFrontPhoto = "";
        this.formKyc.passbookPhoto = "";
        this.formKyc.accountNo = "";
        this.adharBackImg = this.$store.state.placeholderImg;
        this.adharFrontImg = this.$store.state.placeholderImg;
        this.panImg = this.$store.state.placeholderImg;
        // this.passBookImg = this.$store.state.placeholderImg;
      } else {
        // alert('edit')
        
     
        this.kycModalHeading = "Update";
        var data = value;
        // alert('this is the distributer id  distributerkycValueAsign' + this.distributer_id)
        this.formKyc = value.distributer_kyc;
        this.formKyc.panNo = value.distributer_kyc.panNo;
        this.formKyc.aadharNo = value.distributer_kyc.aadharNo;
        this.formKyc.accountNo = value.distributer_kyc.accountNo;

        if(value.distributer_kyc.panNo){
    
          this.panVarifyingStatus = 'true'
        
        }
        if (this.formKyc.panPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/distributer/" +
            this.distributer_id +
            "/" +
            this.formKyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/distributer/" +
              this.distributer_id +
              "/" +
              this.formKyc.panPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/distributer/" +
            this.distributer_id +
            "/thumbs/" +
            this.formKyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/distributer/" +
              this.distributer_id +
              "/thumbs/" +
              this.formKyc.panPhoto;
          }

          this.extensionkycPanPhoto = this.panImg.split(".").pop();

          if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
            this.panImg = "/assets/image/pdf.png";
          }
        } else {
          this.panImg = this.$store.state.placeholderImg;
        }

        if (this.formKyc.aadharFrontPhoto != null) {
          // this.adharFrontImg = this.$store.state.imgUrl + '/admin/' + this.admin_id + '/thumbs/' + this.formKyc.aadharFrontPhoto
          if (
            this.$store.state.imgUrl +
            "/distributer/" +
            this.distributer_id +
            "/" +
            this.formKyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/distributer/" +
              this.distributer_id +
              "/" +
              this.formKyc.aadharFrontPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/distributer/" +
            this.distributer_id +
            "/thumbs/" +
            this.formKyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/distributer/" +
              this.distributer_id +
              "/thumbs/" +
              this.formKyc.aadharFrontPhoto;
          }

          this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

          if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
            this.adharFrontImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharFrontImg = this.$store.state.placeholderImg;
        }

        if (this.formKyc.aadharBackPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/distributer/" +
            this.distributer_id +
            "/" +
            this.formKyc.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/distributer/" +
              this.distributer_id +
              "/" +
              this.formKyc.aadharBackPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/distributer/" +
            this.distributer_id +
            "/thumbs/" +
            this.formKyc.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/distributer/" +
              this.distributer_id +
              "/thumbs/" +
              this.formKyc.aadharBackPhoto;
          }

          this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

          if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
            this.adharBackImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharBackImg = this.$store.state.placeholderImg;
        }

        this.distributerKycVal = "true";
        console.log(data);
      }
     
    },
    businessPanVeryfied() {
      var data = this.formKyc.panNo;
      if (data.length == 10) {
        this.submitStatus = "false";
        $("#adminPanVeryfied").html("<small></small>");
        // this.msgPanError = "";
        this.loading = true;
        this.$axios
          .post(
            "masterdistributer/verifypan",
            { panNo: this.formKyc.panNo },
            { headers: { Authorization: "Bearer " + localStorage.accessToken } }
          )
          .then((res) => {
            if (res.data.data.message) {
              // this.msgPanError = res.data.data.message;
              this.submitStatus = "true";
              $("#adminPanVeryfied").html("<small>" + res.data.data.message + "</small>");
            }
            if (res.data.data.data.status != 'VALID') {
              $("#adminPanVeryfied").html(
                "<small>" + res.data.data.data.status + " Pan </small>"
              );
            }
            if (res.data.data.data.status == 'VALID') {
              this.submitStatus = "false";
              $("#adminPanVeryfied").html("<small></small>");
              this.panVarifyingStatus = "true";
           
              
              toast.success("Pan Verified Succesfully", {
            autoClose: 1000,
          });
            }
          }) .finally(() => (this.loading = false))
          .catch((error) => {
            this.submitStatus = "true";
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
      } else {
        this.submitStatus == "true";
        $("#adminPanVeryfied").html("<small> Pan required 10 Digits</small>");
      }

      // this.panNo = ''
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        $("#adminPanVeryfied").html("<small>special characters are not allowed</small>");
        e.preventDefault();
      } else {
        $("#adminPanVeryfied").html("<small></small>");
      }
    },
  
    distributerkycCreate() {
    

      if(this.professionalKycAddar == 'false' && this.submitStatus == 'false'){
        const formData = new FormData();
      const keys = Object.keys(this.formKyc);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.formKyc[keys[n]] !== null) {
          formData.append(keys[n], this.formKyc[keys[n]]);
        }
      }
      formData.append("distributer_id", this.distributer_id);
      console.log(formData);
      // alert('befor distributer kyc create ')
      this.loading = true;
      this.$axios
        .post("masterdistributer/distributerkyc", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // alert('after distributer kyc create ')
          console.log(res.data.data);

          toast.success("Kyc Created Succesfully", {
            autoClose: 1000,
          });
          $("#kycModal").modal("hide");
          this.formKyc.panNo = "";
          this.formKyc.panPhoto = "";
          this.formKyc.accountType = "";
          this.formKyc.aadharNo = "";
          this.formKyc.remark = "";
          this.formKyc.aadharBackPhoto = "";
          this.formKyc.aadharFrontPhoto = "";
          this.formKyc.passbookPhoto = "";
          this.formKyc.accountNo = "";
          this.adharBackImg = this.$store.state.placeholderImg;
          this.adharFrontImg = this.$store.state.placeholderImg;
          this.panImg = this.$store.state.placeholderImg;
          this.passBookImg = this.$store.state.placeholderImg;
          this.loadRetailers();
          this.distributerKycLoad();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {

this.formKyc.errors = error.response.data


})
      }
    

    },

    distributerkycUpdate() {
      if(this.professionalKycAddar == 'false' && this.submitStatus == 'false'){
        const formData = new FormData();
      const keys = Object.keys(this.formKyc);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.formKyc[keys[n]] !== null) {
          formData.append(keys[n], this.formKyc[keys[n]]);
        }
      }
      formData.append("distributer_id", this.distributer_id);
      this.loading = true;
      this.$axios
        .post(`masterdistributer/distributerkyc/${this.formKyc.id}?_method=PUT`, formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);

          toast.success("Kyc Updated Succesfully", {
            autoClose: 1000,
          });
          $("#kycModal").modal("hide");
          this.loadRetailers();
          this.distributerKycLoad();
          this.formKyc.panNo = "";
          this.formKyc.panPhoto = "";
          this.formKyc.accountType = "";
          this.formKyc.aadharNo = "";
          this.formKyc.remark = "";
          this.formKyc.aadharBackPhoto = "";
          this.formKyc.aadharFrontPhoto = "";
          this.formKyc.passbookPhoto = "";
          this.formKyc.accountNo = "";
          this.adharBackImg = this.$store.state.placeholderImg;
          this.adharFrontImg = this.$store.state.placeholderImg;
          this.panImg = this.$store.state.placeholderImg;
          this.passBookImg = this.$store.state.placeholderImg;

        })
        .finally(() => (this.loading = false))
        .catch((error) => {

this.formKyc.errors = error.response.data


})
}
    
    },

    valueAssignRetailer(distributer) {
      this.submitStatusForFirm = "false";
              $("#firmPanVeryfied").html("<small></small>");
              this.panVerifyForFirm = "false";
      this.takeFormNull()
console.log(distributer)
      this.msgImgError = ""
      this.form = {};
      this.form.errors = {};


      this.heading = "Update";
      this.form = distributer;

      if(distributer.bussinessPan){
  this.panVerifyForFirm = 'true'
}else{
  this.panVerifyForFirm = 'false'
}
// alert(distributer.bussinessPan)
// alert(this.panVerifyForFirm)
      if(distributer.remark){
this.form.remark = distributer.remark
}else{
  this.form.remark = "" 
}

if(distributer.alternativePhone){
this.form.alternativePhone = distributer.alternativePhone
}else{
  this.form.alternativePhone = "" 
}




      this.distributer_id = distributer.id;
      this.edit = "true";
      if (distributer.photo != null) {
        this.photo =
          this.$store.state.imgUrl +
          "/distributer/" +
          distributer.id +
          "/thumbs/" +
          distributer.photo;
      } else {
        this.photo = this.$store.state.placeholderImg;
      }
    },
    updateRetailer() {
      console.log("======== Iam Update ================");
      console.log(this.form);
      if(this.proPassword == 'false' &&  this.pinCode == 'false' &&  this.phonePro == 'false' && this.altPhonePro == 'false' && this.tdsVal == 'false' && this.cinVal == 'false'  && this.gstinVal == 'false'  && this.businessPinCode == 'false' && this.gender == 'false' && this.submitStatusForFirm == 'false'){

        const formData = new FormData();
      const keys = Object.keys(this.form);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.form[keys[n]] !== null) {
          formData.append(keys[n], this.form[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post(`masterdistributer/distributer/${this.distributer_id}?_method=put`, formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Distributer Updated Successfully", {
            autoClose: 1000,
          });
          this.form = {};
          this.edit = "false";
          $("#Retailers").modal("hide");
          this.loadRetailers();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {

this.form.errors = error.response.data

// this.$swal.fire({
//   confirmButtonColor: "#00364f",
//   icon: "error",
//   html: "<small>" + error.response.data.error + "</small>",
// });
})
}

    },

    createNewRetailer() {
      console.log("======== Iam Create ================");
      console.log(this.form);
   
      
      if( this.proPassword == 'false' &&  this.pinCode == 'false' &&  this.phonePro == 'false' && this.altPhonePro == 'false' && this.tdsVal == 'false' && this.cinVal == 'false'  && this.gstinVal == 'false'  && this.businessPinCode == 'false' && this.gender == 'false' && this.submitStatusForFirm == 'false'){
        const formData = new FormData();
      const keys = Object.keys(this.form);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.form[keys[n]] !== null) {
          formData.append(keys[n], this.form[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post("masterdistributer/distributer", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Distributer Created Successfully", {
            autoClose: 1000,
          });
          this.form = {};
          $("#Retailers").modal("hide");
          this.loadRetailers();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {

          this.form.errors = error.response.data

  // this.$swal.fire({
  //   confirmButtonColor: "#00364f",
  //   icon: "error",
  //   html: "<small>" + error.response.data.error + "</small>",
  // });
})
      }

    
    },

    uploadImg(e) {
      // this.photo = URL.createObjectURL(e.target.files["0"]);
      // this.form.photo = e.target.files["0"];


      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Profile Image Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#image-upload").value;
        this.extensionProfile = fileName.split(".").pop();
        if (
          this.extensionProfile == "png" ||
          this.extensionProfile == "jpg" ||
          this.extensionProfile == "jpeg" ||
          this.extensionProfile == "PNG" ||
          this.extensionProfile == "JPG" ||
          this.extensionProfile == "JPEG"
        ) {
          // this.profilePreImg = URL.createObjectURL(e.target.files["0"]);
          // this.form.photo = e.target.files["0"];

          this.photo = URL.createObjectURL(e.target.files["0"]);
          this.form.photo = e.target.files["0"];
        } else {
          this.msgImgError = "File Type Must JPG , PNG, JPEG";
        }
      }
    },
    valueNullFilter(){
  this.filterValue = "";
},
    loadRetailers(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        if (this.filterType == "all") {
          this.filterValue = "";
          this.filterType = "";
        }

        if (this.filterValue != null && this.filterType != "all") {
          if (this.filterType == "name") {
            pageUrl += `masterdistributer/distributer?per_page=${this.per_page}&searchTerm=${this.filterValue}`;
          } else {
            pageUrl += `masterdistributer/distributer?per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
          }
        } else {
          pageUrl += `masterdistributer/distributer?per_page=${this.per_page}`;
        }
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.distributers = res.data.data.data;
          this.pagination = res.data.data;
        })
        .finally(() => (this.loading = false));
    },







    profilePhotoFinder(distributer) {
      var photo;
      if (distributer.photo != null) {
        photo =
          this.$store.state.imgUrl +
          "/distributer/" +
          distributer.id +
          "/thumbs/" +
          distributer.photo;
        return photo;
      } else {
        photo = this.$store.state.placeholderImg;
        return photo;
      }
    },
    loadDistributer() {
      this.$axios
        .post(
          `masterdistributer/user`,
          {},
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          // console.log('-----------------------------userdata--------------------------------')
          console.log(res.data);
          this.form.secretKey = res.data.admin.secretKey;
          // alert(this.form.secretKey)
        });
    },
    loadStates() {
      this.$axios
        .get(`state?all=all`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);
          this.states = res.data.data;
        });
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.masterDistributerPageTitles.distributer
    console.log(this.$store.state.masterDistributerPageTitles.distributer)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadRetailers();
      this.loadDistributer();
      this.loadStates();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.form-check-input[data-v-9cc828ca]:checked {
  border-width: 4px;
  background-color: #f9f5f5;
  border-color: #f21300;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}

.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}


.form-check-input:checked {
  background-color: #ffffff;
  border-color: #f21000;
  border-width: 5px;
}
.form-check-input:not(:disabled):checked {
  box-shadow: 0 2px 4px 0 rgb(225 0 0 / 28%);
}



.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 10px 10px 0px 10px;
}
</style>
